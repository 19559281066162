<template>
  <div class="login">
    <div class="content">{{text}}</div>
  </div>
</template>

<script>
  import auth from "@/util/auth";
  import {base64Decode} from "@/util/base64Util";

  export default {
    data() {
      return {
        logined: false,
        loginLoading: true,
        text: "",
        username: "",
        password: "",
      };
    },
    mounted() {
      this.openFullScreen();
      this.username = this.$route.params.username;
      this.password = base64Decode(this.$route.params.password);
      this.handleSubmit();
    },
    methods: {
      openFullScreen() {
        const loading = this.$loading({
          lock: true,
          text: "正在登录 。。",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        setTimeout(() => {
          if (!this.loginLoading) {
            loading.close();
          }
          if (this.logined) {
            let redirectUrl = "/";
            this.$router.push({path: redirectUrl});
          }
        }, 500);
      },
      handleSubmit() {
        let params = {
          username: this.username,
          password: this.password,
        };
        this.$api.getData("account/login", params).then((res) => {
          this.logined = true;
          this.loginLoading = false;
          res.data.username = this.username;
          auth.login(res.data);
        }).catch(error => {
          this.loginLoading = false;
          if (error.response) {
            this.text = "登录失败，" + error.response.data.message;
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.content {
  color: #606266;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
}
</style>
